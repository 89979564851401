exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cultures-js": () => import("./../../../src/pages/cultures.js" /* webpackChunkName: "component---src-pages-cultures-js" */),
  "component---src-pages-events-creation-myths-js": () => import("./../../../src/pages/events/creation-myths.js" /* webpackChunkName: "component---src-pages-events-creation-myths-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-region-americas-js": () => import("./../../../src/pages/region/americas.js" /* webpackChunkName: "component---src-pages-region-americas-js" */),
  "component---src-pages-region-asia-pacific-js": () => import("./../../../src/pages/region/asia-pacific.js" /* webpackChunkName: "component---src-pages-region-asia-pacific-js" */),
  "component---src-pages-region-australia-oceania-js": () => import("./../../../src/pages/region/australia-oceania.js" /* webpackChunkName: "component---src-pages-region-australia-oceania-js" */),
  "component---src-pages-region-caribbean-js": () => import("./../../../src/pages/region/caribbean.js" /* webpackChunkName: "component---src-pages-region-caribbean-js" */),
  "component---src-pages-region-central-america-js": () => import("./../../../src/pages/region/central-america.js" /* webpackChunkName: "component---src-pages-region-central-america-js" */),
  "component---src-pages-region-central-south-asia-js": () => import("./../../../src/pages/region/central-south-asia.js" /* webpackChunkName: "component---src-pages-region-central-south-asia-js" */),
  "component---src-pages-region-eastern-europe-js": () => import("./../../../src/pages/region/eastern-europe.js" /* webpackChunkName: "component---src-pages-region-eastern-europe-js" */),
  "component---src-pages-region-europe-js": () => import("./../../../src/pages/region/europe.js" /* webpackChunkName: "component---src-pages-region-europe-js" */),
  "component---src-pages-region-middle-east-africa-js": () => import("./../../../src/pages/region/middle-east-africa.js" /* webpackChunkName: "component---src-pages-region-middle-east-africa-js" */),
  "component---src-pages-region-middle-east-js": () => import("./../../../src/pages/region/middle-east.js" /* webpackChunkName: "component---src-pages-region-middle-east-js" */),
  "component---src-pages-region-north-america-js": () => import("./../../../src/pages/region/north-america.js" /* webpackChunkName: "component---src-pages-region-north-america-js" */),
  "component---src-pages-region-northeastern-asia-js": () => import("./../../../src/pages/region/northeastern-asia.js" /* webpackChunkName: "component---src-pages-region-northeastern-asia-js" */),
  "component---src-pages-region-northern-africa-js": () => import("./../../../src/pages/region/northern-africa.js" /* webpackChunkName: "component---src-pages-region-northern-africa-js" */),
  "component---src-pages-region-northern-europe-js": () => import("./../../../src/pages/region/northern-europe.js" /* webpackChunkName: "component---src-pages-region-northern-europe-js" */),
  "component---src-pages-region-south-america-js": () => import("./../../../src/pages/region/south-america.js" /* webpackChunkName: "component---src-pages-region-south-america-js" */),
  "component---src-pages-region-southeastern-asia-js": () => import("./../../../src/pages/region/southeastern-asia.js" /* webpackChunkName: "component---src-pages-region-southeastern-asia-js" */),
  "component---src-pages-region-southern-africa-js": () => import("./../../../src/pages/region/southern-africa.js" /* webpackChunkName: "component---src-pages-region-southern-africa-js" */),
  "component---src-pages-region-southern-europe-js": () => import("./../../../src/pages/region/southern-europe.js" /* webpackChunkName: "component---src-pages-region-southern-europe-js" */),
  "component---src-pages-region-western-europe-js": () => import("./../../../src/pages/region/western-europe.js" /* webpackChunkName: "component---src-pages-region-western-europe-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

